import React from 'react';
import Layout from "../../layout/Layout";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import SubmitApplicationBlock from "../../blocks/static/SubmitApplicationBlock";
import StepsBlock from "../../blocks/StepsBlock";
import InfoCardsBlock from "../../blocks/InfoCardsBlock";

import SEO from "../../components/seo";

import enMessages from '../../i18n/paymentAccount/en.js'
import ruMessages from '../../i18n/paymentAccount/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const VatPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <PageHeaderBlock
                locale={locale}
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
                footnotes={messages[locale].pageHeaderBlock.footnotes}
                submitFormCaption={messages[locale].submitApplicationBlock.textSubmit}
                heroBanner={messages[locale].pageHeaderBlock.heroBanner}
                classNameBlock="BlockHeroBanner"
            />
            <InfoCardsBlock
                cardsTitle={messages[locale].infoCardsBlock.cardsTitle}
                headerStyleNumb={messages[locale].infoCardsBlockSecond.headerStyleNumb}
                cardsLayout="row"
                cardsTitleColor="second"
                cards={messages[locale].infoCardsBlock.cards}
                listTitle={messages[locale].infoCardsBlock.listTitle}
                listPosition="afterCards"
                listColor="first"
                list={messages[locale].infoCardsBlock.list}
                listGoTo={messages[locale].infoCardsBlock.listGoTo}
            />

            <InfoCardsBlock
                cardsTitle={messages[locale].infoCardsBlockSecond.cardsTitle}
                headerStyleNumb={messages[locale].infoCardsBlockSecond.headerStyleNumb}
                cardsTitleColor="first"
                cardsLayout="column"
                cards={messages[locale].infoCardsBlockSecond.cards}
                config={{
                    animation: '2',
                    baseBorder: true
                }}
                submitForm="show"
                submitFormCaption={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
            />

        </Layout>
    )
};

export default VatPage;
