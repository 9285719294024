import iconWorld from "../../images/icons/styled/first/World.svg";
import iconLike from "../../images/icons/styled/first/CurrencyControl.svg";
import iconCheckStar from "../../images/icons/styled/first/CheckInStar.svg";
import links from "../../utils/links";

import heroBanner from "../../images/heroBanner/PaymentAccount.svg";

export default {
    "seo": {
        "title": "Расчетный счет для бизнеса | Банк 131",
        "description": "Откройте расчетный счет для бизнеса. Быстрое оформление расчетного счета онлайн в банке. Комплаенс-поддержка. Персональный менеджер. Номинальный счет. Международные расчёты."
    },
    "pageHeaderBlock": {
        "title": "Расчетный счет <br />для бизнеса",
        "text": "Принимайте и отправляйте платежи <br />в рублях и других валютах.",
        "heroBanner": heroBanner
    },

    "infoCardsBlock": {
        "cardsTitle": "Преимущества обслуживания",
        "headerStyleNumb": "13",
        "cards": [
            {
                "title": "Комплаенс-поддержка",
                "text": 'Поможем избежать ошибок и сомнительных операций',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            },
            {
                "title": "Персональный менеджер",
                "text": 'На связи в любом удобном мессенджере',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            },
            {
                "title": "Номинальный счет",
                "text": 'Чтобы разделить деньги пользователей и платформы',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            },
            {
                "title": "Международные расчёты",
                "text": 'Для нерезидентов и компаний, которые работают не только в России',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            }
        ]
    },

    "infoCardsBlockSecond": {
        "cardsTitle": "Если вы работаете с другими странами",
        "headerStyleNumb": "13",
        "cards": [
            {
                "title": "Валютный контроль без стресса",
                "text": 'Поможем заполнить формы валютного контроля. Для крупных сделок предложим индивидуальный курс конвертации.',
                "icon": iconLike,
                "theme": 'withoutBackground'
            },
            {
                "title": "Удобно для нерезидентов",
                "text": 'Онлайн-банк доступен на английском языке, а процесс подключения проходит комфортно с непрерывным сопровождением менеджера. ',
                "icon": iconWorld,
                "theme": 'withoutBackground'
            },
        ]
    },
    "submitApplicationBlock": {
        "textSubmit": "Открыть счет"
    }
}
