import iconWorld from "../../images/icons/styled/first/World.svg";
import iconLike from "../../images/icons/styled/first/CurrencyControl.svg";
import iconCheckStar from "../../images/icons/styled/first/CheckInStar.svg";
import links from "../../utils/links";

import heroBanner from "../../images/heroBanner/PaymentAccount.svg";

export default {
    "seo": {
        "title": "Current accounts for businesses | Bank 131",
        "description": "Open a current account for your business. Opening an account online is quick. Compliance support. Personal manager. Temporary account. International settlements."
    },
    "pageHeaderBlock": {
        "title": "Business <br /> accounts",
        "text": "Receive payments and settle<br />locally in RUB.",
        "heroBanner": heroBanner
    },

    "infoCardsBlock": {
        "cardsTitle": "Why us",
        "headerStyleNumb": "13",
        "cards": [
            {
                "title": "Compliance",
                "text": 'We guide you through Russian regulation.',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            },
            {
                "title": "Personal manager",
                "text": 'We are available to you anytime, anywhere.',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            },
            {
                "title": "Escrow account",
                "text": 'Keep clients’ funds separate.',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            },
            {
                "title": "Cross-border remittance",
                "text": 'In major currencies via SWIFT.',
                "icon": iconCheckStar,
                "theme": 'cardSecond'
            }
        ]
    },

    "infoCardsBlockSecond": {
        "cardsTitle": "Stress-free local operations in Russia",
        "headerStyleNumb": "13",
        "cards": [
            {
                "title": "Local tax ID",
                "text": 'We will help you to get the required Tax ID for foreign companies.',
                "icon": iconLike,
                "theme": 'withoutBackground'
            },
            {
                "title": "Language-friendly",
                "text": 'Our online banking interface speaks in English. ',
                "icon": iconWorld,
                "theme": 'withoutBackground'
            },
        ]
    },
    "submitApplicationBlock": {
        "textSubmit": "Open account",
        "textSubmitBottom": "Get in touch",
    }
}
